import { PencilSquareIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import {
  DisbursementSchedMap,
  DisbursementStartPurMap,
  InputType,
  MISMOPrepaidItemType,
  PaidByMap,
  PaidToMap,
} from 'config'
import { useEffect, useState } from 'react'
import { type FormTableHeader, FormTable, Modal } from 'stories/components'
import { formatDate, getPrice1or2decimal } from 'utils/convertor'
import { RenderInput } from 'utils/RenderInput'

import { OverrideCaclModal } from '../OverrideCalcModal/OverrideCalcModal'

interface PrepaidItemsDetailsModalProps {
  /**
   * Closing Costs Details
   */
  prepaidItems?: Record<string, any>
  /**
   * On close handler
   */
  onClose?: Function
  /**
   * On submit handler
   */
  onSubmit?: Function

  canEdit?: boolean
}
const disbListHeader: FormTableHeader[] = [
  { key: 'date', title: 'Date' },
  { key: 'amount', title: 'Amount' },
]

const disbPeriodHeader: FormTableHeader[] = [
  { key: 'month', title: 'Month' },
  { key: 'disbursed', title: 'Months Disbursed' },
]

const defaultDisbPeriod = [
  {
    month: 'Jan',
    disbursed: '',
    id: 1,
  },
  {
    month: 'Feb',
    disbursed: '',
    id: 2,
  },
  {
    month: 'Mar',
    disbursed: '',
    id: 3,
  },
  {
    month: 'Apr',
    disbursed: '',
    id: 4,
  },
  {
    month: 'May',
    disbursed: '',
    id: 5,
  },
  {
    month: 'Jun',
    disbursed: '',
    id: 6,
  },
  {
    month: 'Jul',
    disbursed: '',
    id: 7,
  },
  {
    month: 'Aug',
    disbursed: '',
    id: 8,
  },
  {
    month: 'Sep',
    disbursed: '',
    id: 9,
  },
  {
    month: 'Oct',
    disbursed: '',
    id: 10,
  },
  {
    month: 'Nov',
    disbursed: '',
    id: 11,
  },
  {
    month: 'Dec',
    disbursed: '',
    id: 12,
  },
]

export const disbListInputs = (): Record<string, InputType> => {
  return {
    date: {
      inputType: 'text',
      type: 'date',
      title: 'Date',
      required: true,
    },
    amount: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Amount',
      required: true,
      prefix: '$',
    },
  }
}

export const disbPeriodInputs = (): Record<string, InputType> => {
  return {
    month: {
      inputType: 'text',
      type: 'text',
      title: 'Month',
      disabled: true,
    },
    disbursed: {
      inputType: 'text',
      type: 'number',
      maxNumberRange: 12,
      decimalStep: -1,
      title: 'Months Disbursed',
    },
  }
}

const generalInputs = (): Record<string, InputType | any> => {
  return {
    section: {
      inputType: 'group',
      title: 'General',
      prefix: '$',
      className: 'col-span-12',
    },
    prepaidItemType: {
      inputType: 'select',
      title: 'Type',
      options: MISMOPrepaidItemType,
      className: 'col-span-6',
    },
    custom: {
      inputType: 'custom',
      className: 'col-span-6',
    },
    payment: {
      inputType: 'text',
      title: 'Monthly Payment',
      prefix: '$',
      contentType: 'number',
      className: 'col-span-6',
    },
    periodicPayment: {
      inputType: 'text',
      title: 'Exact Periodic Payment',
      contentType: 'number',
      className: 'col-span-6',
    },
  }
}

const escrowInputs = (): Record<string, InputType | any> => {
  return {
    section: {
      inputType: 'group',
      title: 'Escrow Info',
      className: 'col-span-12',
    },
    disbSched: {
      inputType: 'select',
      title: 'Disbursement Sched',
      options: DisbursementSchedMap,
      className: 'col-span-6',
    },
    starting: {
      inputType: 'select',
      title: 'Starting',
      options: DisbursementStartPurMap,
      className: 'col-span-6',
    },
    cushion: {
      inputType: 'text',
      title: 'Cushion',
      disabled: true,
      contentType: 'number',
      className: 'col-span-6',
    },
    disbStartYear: {
      inputType: 'text',
      title: 'Year',
      contentType: 'number',
      className: 'col-span-6',
    },
  }
}

const premiumInputs = (): Record<string, InputType | any> => {
  return {
    section: {
      inputType: 'group',
      title: 'Premium',
      className: 'col-span-12',
    },
    formula: {
      inputType: 'label',
      title:
        '<strong>* Months In Advance</strong> × <strong>Payment</strong> = <strong>Hazard Insurance Premium</strong>',
      className: 'col-span-12',
    },
    monthsInAdvance: {
      inputType: 'text',
      title: 'Months In Advance',
      contentType: 'number',
      className: 'col-span-3',
    },
    payment: {
      inputType: 'text',
      title: 'Payment',
      prefix: '$',
      contentType: 'number',
      className: 'col-span-3',
    },
    premium: {
      inputType: 'text',
      title: 'Hazard Insurance Premium',
      prefix: '$',
      disabled: true,
      contentType: 'number',
      className: 'col-span-6',
    },
    premiumPBS: {
      inputType: 'text',
      title: 'Paid By Others',
      prefix: '$',
      contentType: 'number',
      className: 'col-span-6',
    },
    premiumPaidByOther: {
      inputType: 'select',
      title: 'Paid By',
      options: PaidByMap,
      className: 'col-span-6',
    },
    premiumPOC: {
      inputType: 'checkbox',
      title: 'Paid Outside of Closing',
      className: 'col-span-6',
    },
    netFromWire: {
      inputType: 'checkbox',
      title: 'Net From Wire',
      className: 'col-span-6',
    },
    paidTo: {
      inputType: 'select',
      title: 'Paid To',
      options: PaidToMap,
      className: 'col-span-6',
    },
    premPointsAndFees: {
      inputType: 'text',
      title: 'Points and Fees Amount',
      prefix: '$',
      disabled: true,
      contentType: 'number',
      className: 'col-span-6',
    },
  }
}

const reserveInputs = (): Record<string, InputType | any> => {
  return {
    section: {
      inputType: 'group',
      title: 'Reserves',
      className: 'col-span-12',
    },
    formula: {
      inputType: 'label',
      title:
        '<strong>* Months In Reserves</strong> × <strong>Payment</strong> = <strong>Hazard Insurance Reserves</strong>',
      className: 'col-span-12',
    },
    monthsInReserve: {
      inputType: 'text',
      title: 'Months In Reserves',
      contentType: 'number',
      className: 'col-span-3',
    },
    payment: {
      inputType: 'text',
      title: 'Payment',
      prefix: '$',
      contentType: 'number',
      className: 'col-span-3',
    },
    reserve: {
      inputType: 'text',
      title: 'Hazard Insurance Reserves',
      prefix: '$',
      disabled: true,
      contentType: 'number',
      className: 'col-span-6',
    },
    reservePBS: {
      inputType: 'text',
      title: 'Paid By Others',
      prefix: '$',
      contentType: 'number',
      className: 'col-span-6',
    },
    reservePaidByOther: {
      inputType: 'select',
      title: 'Paid By',
      options: PaidByMap,
      className: 'col-span-6',
    },
    estimate: {
      inputType: 'text',
      title: 'Estimate',
      prefix: '$',
      disabled: true,
      contentType: 'number',
      className: 'col-span-6',
    },
    reservesPOC: {
      inputType: 'checkbox',
      title: 'Paid Outside of Closing',
      className: 'col-span-6',
    },
    resPointsAndFees: {
      inputType: 'text',
      title: 'Points and Fees Amount',
      prefix: '$',
      disabled: true,
      contentType: 'number',
      className: 'col-span-6',
    },
    atrSection: {
      inputType: 'group',
      title: 'ATR Notes',
      className: 'col-span-12',
    },
    atrNotes: {
      inputType: 'textarea',
      title: 'ATR Notes',
      className: 'col-span-12',
    },
  }
}

export const PrepaidItemsDetailsModal = ({
  prepaidItems = {},
  onClose = () => {},
  onSubmit = () => {},
  canEdit = true,
}: PrepaidItemsDetailsModalProps) => {
  const [data, setData] = useState<Record<string, any>>({})
  const [ovData, setOVData] = useState<any>({})
  const [disbList, setDisbList] = useState<Record<string, any>[]>([])
  const [disbPeriod, setDisbPeriod] = useState<Record<string, any>[]>(defaultDisbPeriod)
  const generalInput = generalInputs()
  const escrowInput = escrowInputs()
  const premiumInput = premiumInputs()
  const reserveInput = reserveInputs()

  useEffect(() => {
    setData(prepaidItems)

    if (prepaidItems.disbList?.value.length > 0) {
      const disbSchedItems = prepaidItems.disbList?.value.split('|')
      let tempList: Record<string, any>[] = []
      disbSchedItems.map((v: any, id: number) => {
        const [date, amount] = v.split('~')
        tempList.push({ date, amount, id: id + 1 })
      })
      setDisbList(tempList)
    }
    if (prepaidItems.disbPeriods?.value.length > 0) {
      let tempPeriod = cloneDeep(disbPeriod)
      tempPeriod.forEach((v: any, index: number) => {
        if (prepaidItems.disbPeriods?.value[index] != '.')
          tempPeriod[index].disbursed = prepaidItems.disbPeriods?.value[index].charCodeAt(0) - 'A'.charCodeAt(0) + 1
      })
      setDisbPeriod(tempPeriod)
    }
  }, [prepaidItems])

  const onModalClose = () => {
    onClose(false)
  }

  const onModalSubmit = () => {
    const temp = cloneDeep(data)

    let disbSchedStr = ''
    disbList.map((item: any) => {
      disbSchedStr += `${item.date}~${item.amount}|`
    })
    temp.disbList.value = disbSchedStr.slice(0, -1)

    let disbPeriodStr = ''
    disbPeriod.forEach((item: any) => {
      if (item.disbursed > 0 && item.disbursed < 13)
        disbPeriodStr += String.fromCharCode(item.disbursed + 'A'.charCodeAt(0) - 1)
      else disbPeriodStr += '.'
    })
    disbPeriodStr += '..............'
    temp.disbPeriods.value = disbPeriodStr
    setData(temp)

    console.log(temp)
    onSubmit(true, temp)
  }

  const onValueChanged = (key: string, value: any) => {
    const temp = cloneDeep(data)
    console.log(disbList)

    let inputs: any = {}
    if (Object.keys(generalInput).includes(key)) inputs = generalInput
    if (Object.keys(escrowInput).includes(key)) inputs = escrowInput
    if (Object.keys(premiumInput).includes(key)) inputs = premiumInput
    if (Object.keys(reserveInput).includes(key)) inputs = reserveInput

    if (inputs[key]?.contentType === 'number') temp[key].value = getPrice1or2decimal(value).replaceAll(',', '')
    else temp[key].value = value

    setData(temp)
  }

  const onDisbListSubmit = (currentId: any, values: Record<string, any>) => {
    return new Promise((resolve) => {
      console.log(currentId)
      values.date = formatDate(values.date)
      const temp = cloneDeep(disbList)
      if (!currentId) {
        if (disbList.length == 0) values.id = 1
        else values.id = disbList[disbList.length - 1].id + 1

        temp.push(values)
      } else {
        temp.forEach((item: any, index: number) => {
          if (item.id == currentId) {
            values.id = temp[index].id
            temp[index] = values
          }
        })
      }

      setDisbList(temp)
      resolve(values)
    })
  }

  const onDisbListRemove = async (id: any) => {
    return new Promise((resolve) => {
      console.log(id)
      if (id) {
        const temp = disbList.filter((item) => item.id != id)
        setDisbList(temp)
      }
      console.log(disbList)
      resolve(true)
    })
  }

  const onDisbPeriodSubmit = (currentId: any, values: Record<string, any>) => {
    return new Promise((resolve) => {
      console.log(values)
      const temp = cloneDeep(disbPeriod)
      if (currentId) {
        temp.forEach((item: any, index: number) => {
          if (item.id == currentId) {
            temp[index].disbursed = +values.disbursed
          }
        })
      }

      setDisbPeriod(temp)
      resolve(values)
    })
  }

  const renderModalInput = (input: any, key: string) => {
    if (
      (key === 'cushion' && ['0', '1', '', 0, 1].includes(data['disbSched']?.value)) ||
      (key === 'starting' && ['0', '1', '2', '6', '7', '', 0, 1, 2, 6, 7].includes(data['disbSched']?.value)) ||
      (key === 'disbStartYear' &&
        (['0', '1', '2', '6', '7', '', 0, 1, 2, 6, 7].includes(data['disbSched']?.value) ||
          ['0', '13', '', 0, 13].includes(data['starting']?.value)))
    )
      return
    input.value = data[key]?.value || ''
    if (input.contentType === 'number' && key != 'disbStartYear') input.value = getPrice1or2decimal(input.value)

    if (key === 'prepaidItemType') {
      input.value = data['prepaidItemType']?.value
      switch (data.title) {
        case 'Association Dues':
          input.value = '1'
          input.disabled = true
          break
        case 'Property Taxes':
          input.value = '3'
          input.disabled = true
          break
        default:
      }
      if (['Hazard Insurance', 'Flood Insurance', ''].includes(data.title)) return
    }
    if (key === 'cushion') {
      input.value = getPrice1or2decimal(data['cushionOV']?.value || data['cushion']?.value || 0)
      if (canEdit)
        input.additionalElements = (
          <span
            className="cursor-pointer hover:text-shade-blue"
            onClick={() =>
              setOVData({
                title: 'Points and Fees Amount',
                overrideKey: 'cushionOV',
                calcValue: data['cushion']?.value || 0,
                ovValue: getPrice1or2decimal(data['cushionOV']?.value || ''),
              })
            }
          >
            <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
          </span>
        )
    }

    if (key === 'premium') {
      input.value = getPrice1or2decimal(
        data['premiumOV']?.value || +data['monthsInAdvance']?.value * +data['payment']?.value,
      )
      if (canEdit)
        input.additionalElements = (
          <span
            className="cursor-pointer hover:text-shade-blue"
            onClick={() =>
              setOVData({
                title: 'Points and Fees Amount',
                overrideKey: 'premiumOV',
                calcValue: getPrice1or2decimal(+data['monthsInAdvance']?.value * +data['payment']?.value),
                ovValue: getPrice1or2decimal(data['premiumOV']?.value || ''),
              })
            }
          >
            <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
          </span>
        )
    }

    if (key === 'premPointsAndFees') {
      input.value = getPrice1or2decimal(data['premPointsAndFeesOV']?.value || 0)
      if (canEdit)
        input.additionalElements = (
          <span
            className="cursor-pointer hover:text-shade-blue"
            onClick={() =>
              setOVData({
                title: 'Points and Fees Amount',
                overrideKey: 'premPointsAndFeesOV',
                calcValue: 0,
                ovValue: getPrice1or2decimal(data['premPointsAndFeesOV']?.value || ''),
              })
            }
          >
            <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
          </span>
        )
    }

    if (key === 'reserve') {
      input.value = getPrice1or2decimal(
        data['reserveOV']?.value || +data['monthsInReserve']?.value * +data['payment']?.value,
      )
      if (canEdit)
        input.additionalElements = (
          <span
            className="cursor-pointer hover:text-shade-blue"
            onClick={() =>
              setOVData({
                title: 'Points and Fees Amount',
                overrideKey: 'reserveOV',
                calcValue: getPrice1or2decimal(+data['monthsInReserve']?.value * +data['payment']?.value),
                ovValue: getPrice1or2decimal(data['reserveOV']?.value || ''),
              })
            }
          >
            <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
          </span>
        )
    }

    if (key === 'resPointsAndFees') {
      input.value = getPrice1or2decimal(data['resPointsAndFeesOV']?.value || data['reserveOV']?.value)
      if (canEdit)
        input.additionalElements = (
          <span
            className="cursor-pointer hover:text-shade-blue"
            onClick={() =>
              setOVData({
                title: 'Points and Fees Amount',
                overrideKey: 'resPointsAndFeesOV',
                calcValue: getPrice1or2decimal(data['reserveOV']?.value),
                ovValue: getPrice1or2decimal(data['resPointsAndFeesOV']?.value || ''),
              })
            }
          >
            <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
          </span>
        )
    }

    return (
      <RenderInput
        input={{ ...input, disabled: canEdit ? input.disabled : true }}
        Key={key}
        onChange={onValueChanged}
      />
    )
  }

  const closeOVModal = (save: false, data: any) => {
    if (save) onValueChanged(ovData.overrideKey, data.ovValue)
    setOVData({})
  }

  return (
    <Modal
      title={data.title}
      titleOkay={canEdit ? 'Okay' : ''}
      isOpen
      onClose={onModalClose}
      onOk={onModalSubmit}
      titleCancel="Cancel"
    >
      <>
        <div className="grid grid-cols-12 gap-4 text-sm">
          {Object.keys(generalInput).map((key) => {
            const input = generalInput[key]
            return (
              <div className={input.className} key={key}>
                {renderModalInput(input, key)}
              </div>
            )
          })}
          {Object.keys(escrowInput).map((key) => {
            const input = escrowInput[key]
            return (
              <div className={input.className} key={key}>
                {renderModalInput(input, key)}
              </div>
            )
          })}
          {[7, '7'].includes(data['disbSched']?.value) && (
            <div className="col-span-12">
              <FormTable
                header={disbListHeader}
                permission={1}
                inputs={disbListInputs}
                defaultData={disbList}
                submitText="Add"
                onSubmit={onDisbListSubmit}
                onRemove={onDisbListRemove}
              />
            </div>
          )}
          {[6, '6'].includes(data['disbSched']?.value) && (
            <div className="col-span-12">
              <FormTable
                header={disbPeriodHeader}
                permission={2.5}
                inputs={disbPeriodInputs}
                defaultData={disbPeriod}
                submitText="Add"
                onSubmit={onDisbPeriodSubmit}
              />
            </div>
          )}
          {Object.keys(premiumInput).map((key) => {
            const input = premiumInput[key]
            return (
              <div className={input.className} key={key}>
                {renderModalInput(input, key)}
              </div>
            )
          })}
          {Object.keys(reserveInput).map((key) => {
            const input = reserveInput[key]
            return (
              <div className={input.className} key={key}>
                {renderModalInput(input, key)}
              </div>
            )
          })}
        </div>
        {Object.keys(ovData).length > 0 && (
          <OverrideCaclModal
            key={ovData.overrideKey}
            title={ovData.title}
            calcValue={ovData.calcValue}
            ovValue={ovData.ovValue}
            onClose={closeOVModal}
          />
        )}
      </>
    </Modal>
  )
}
